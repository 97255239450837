import SideBar, { Icon, SideBarItem } from "@/components/sidebar";
import HeroBanner from "@/components/hero-banner";
import Messages from "@/pages/UnitPage/Sections/Messages/index";
import { BannerBackground, ContentWrapper, InnerPageWrapper, OuterWrapper, Wrapper } from './style'
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getBaseUnitPage } from "@/services/UnitPage/commonService";
import { deleteMessage, getMessages, updateMessage } from "@/services/UnitPage/messageService";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { IBaseUnitPage } from "@/models/UnitPage/baseUnitPage";
import { IMessage } from "@/models/UnitPage/message";
import { ToastContext } from "@/components/toast";
import { useTranslation } from "react-i18next";
import { HTTP_STATUS_CODES } from "@/utils/api";
import { ROUTES } from "@/helpers/urlHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo } from "@fortawesome/pro-solid-svg-icons/faSquareInfo";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faBoxOpen } from "@fortawesome/pro-solid-svg-icons/faBoxOpen";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faFile } from "@fortawesome/pro-solid-svg-icons/faFile";
import { faStore } from "@fortawesome/pro-solid-svg-icons/faStore";
import { scrollToTop } from "@/helpers/scroll";
import { isCaseInsensitiveMatch } from "@/helpers/validators";

const UnitPageLayout = () => {
  const [loading, setLoading] = useState(true)
  const [baseUnitPage, setBaseUnitPage] = useState<IBaseUnitPage>()
  const params = useParams();
  const { showToast } = useContext(ToastContext);
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchBaseUnitPage()
  }, [params.unitNumber]);

  const fetchBaseUnitPage = async () => {
    setLoading(true)
    getBaseUnitPage(params.unitNumber).then(res => {
      setLoading(false)
      if (res.ok) {
        setBaseUnitPage(res.body);
      } else {
        if (res.status === HTTP_STATUS_CODES.NotFound) {
          navigate(ROUTES.Page404)
        } else {
          navigate(ROUTES.Page500)
        }
      }
    })
  }

  const refreshMessages = async () => {
    const res = await getMessages(params.unitNumber)
    if (res.ok) {
      setBaseUnitPage({
        ...baseUnitPage,
        messages: res.body
      })
    } else
      showToast(t('toast.fetchFailed', { module: t('unitPage.messageSection.messages') }), 'error-fetch')

  }

  const onMessageSave = async (message: IMessage) => {
    const ok = updateMessage(params.unitNumber, message)
    if (ok) {
      showToast(t("toast.changesSaved"), "success");
      await refreshMessages();
    } else
      showToast(t("toast.changesNotSaved"), "error");
    return ok
  }

  const onMessageDelete = async (idMessage: number): Promise<boolean> => {
    const ok = await deleteMessage(params.unitNumber, idMessage);
    if (ok) {
      showToast(t('toast.deleteSuccessful', { module: t('unitPage.messageSection.messages') }), 'success')
      await refreshMessages();
    } else
      showToast(t('toast.deleteFailed', { module: t('unitPage.messageSection.messages') }), 'error')
    return ok;
  }

  const [lastPathSegment, setLastPathSegment] = useState(location.pathname.split("/").at(-1))

  useEffect(() => {
    setLastPathSegment(location.pathname.split("/").at(-1))
    scrollToTop()
  }, [location])

  return (
    <OuterWrapper>
      <Wrapper>
        <BannerBackground>
          <HeroBanner loading={loading} title={baseUnitPage?.name} topTitle={`${baseUnitPage?.unitNumber} ${baseUnitPage?.unitTypeName}`} />
        </BannerBackground>
        <InnerPageWrapper>
          <SideBar loading={loading} loadingSpinners={5}>
            {baseUnitPage?.sideMenu?.general && <Link to="General">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'General') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faSquareInfo} /></Icon>
                <span>{t('unitPage.sideBarMenu.general')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.contact && <Link to="Contact">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Contact') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faPhone} /></Icon>
                <span>{t('unitPage.sideBarMenu.contact')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.openingHours && <Link to="OpeningHours">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OpeningHours') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faClock} /></Icon>
                <span>{t('unitPage.sideBarMenu.openingTimeAndDeadline')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.servicesAndEquipments && <Link to="ServicesAndEquipments">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'ServicesAndEquipments') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faBoxOpen} /></Icon>
                <span>{t('unitPage.sideBarMenu.servicesAndTools')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.mapAndArea && <Link to="MapAndArea">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'MapAndArea') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faMapMarkerAlt} /></Icon>
                <span>{t('unitPage.sideBarMenu.mapAndArea')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.deals && <Link to="Deals">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Deals') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faFolder} /></Icon>
                <span>{t('unitPage.sideBarMenu.appointments')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.legalInformation && <Link to="LegalInformation">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'LegalInformation') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faFile} /></Icon>
                <span>{t('unitPage.sideBarMenu.legal')}</span>
              </SideBarItem>
            </Link>}
            {baseUnitPage?.sideMenu?.operationalSurvey && <Link to="OperationalSurvey">
              <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OperationalSurvey') ? 'active' : undefined}>
                <Icon><FontAwesomeIcon icon={faStore} /></Icon>
                <span>{t('unitPage.sideBarMenu.operationalSurvey')}</span>
              </SideBarItem>
            </Link>}
          </SideBar>
          <ContentWrapper>
            {
              baseUnitPage?.messages && baseUnitPage?.messages.filter(m => !m.deleted && new Date(m.fromDate).getTime() < Date.now() && Date.now() < new Date(m.toDate).getTime()).length > 0 ? (
                <Messages messages={baseUnitPage?.messages} onSave={onMessageSave} onDelete={onMessageDelete} editableAccess={baseUnitPage?.editableAccess} />
              ) : <></>
            }
            <Outlet context={[baseUnitPage, setBaseUnitPage]} />
          </ContentWrapper>
        </InnerPageWrapper>
      </Wrapper>
    </OuterWrapper>
  );
};

export default UnitPageLayout;

type BaseUnitPageContext = [baseUnitPage: IBaseUnitPage, setBaseUnitPage: Dispatch<SetStateAction<IBaseUnitPage>>]

export const useBaseUnitPageContext = () => {
  return useOutletContext<BaseUnitPageContext>();
}
