import styled from "styled-components";
import { spacing } from "@/ui/spacing"
import { colors } from "@/ui/colors";

export const Container = styled.div`

`;

export const TextContainer = styled.p`
    
`;

export const ButtonContainer = styled.div`
    margin-top: ${spacing.medium3};
`;