import SearchSuggestion, { ISearchSuggestion } from "../search-suggestion"

interface ISearchSuggestionsProps {
  suggestions?: ISearchSuggestion[]
  handleSelection?: () => void
  focusIdx?: number
  loading?: boolean
  onClick?: (e: MouseEvent) => void
}

const SearchSuggestions = ({suggestions, focusIdx, handleSelection, onClick}: ISearchSuggestionsProps) => {
  const handleClick = (e: MouseEvent, clickHandler?: (e: MouseEvent) => void) => {
    if (clickHandler) {
      clickHandler(e)
    }
    if (handleSelection) {
      handleSelection()
    }
    if (onClick) onClick(e);
  }

  return (
    <ul className="hw-search__suggestions" style={{zIndex: 400}}>
      {
        suggestions?.map((s, idx) => (
          <SearchSuggestion key={idx}
                            title={s.title}
                            scrollSuggestionIntoView={s.scrollSuggestionIntoView}
                            focus={idx === focusIdx}
                            href={s.href}
                            subTitle={s.subTitle}
                            onClick={(e) => handleClick(e, s.onClick)}/>
        ))
      }
    </ul>
  )
}

export default SearchSuggestions