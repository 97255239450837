import { pageSpacing, spacing } from "@/ui/spacing";
import styled from "styled-components";
export const MessageContainer = styled.div`
    
`;

export const MessageSectionWrapper = styled.div`
    margin-top: ${spacing.medium3};
    ${pageSpacing.xMargin}
`;

export const ButtonContainer = styled.div`
    margin-top: ${spacing.medium3};
`;