import { ButtonLoading, CloseIcon, Icon, IconLoading, MenuButtonWrapper, SideBarContainer, SideBarItem, SpanLoading } from "./style"
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconSecondaryButton from "@/components/base/icon-secondary-button";
import { faArrowFromLeft } from "@fortawesome/pro-regular-svg-icons/faArrowFromLeft";
import { faArrowLeftFromLine } from "@fortawesome/pro-regular-svg-icons/faArrowLeftFromLine";
import { useLocation } from "react-router-dom";

interface ISideBarProps extends PropsWithChildren<any> {
  loading: boolean
  loadingSpinners?: number
}

const SideBar = ({loading, loadingSpinners, children}: ISideBarProps) => {
  const {t} = useTranslation(['translation']);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  function onOpen() {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  return <>
    <MenuButtonWrapper>
      {
        loading ? (
          <ButtonLoading className="loading-content"/>
        ) : (
          <IconSecondaryButton size={'large'} onClick={onOpen} iconLast={true} icon={faArrowFromLeft}>{t('common.menu')}</IconSecondaryButton>
        )
      }
    </MenuButtonWrapper>
    <SideBarContainer className="hw-container" sideBarOpens={isOpen}>
      <CloseIcon onClick={onOpen}><FontAwesomeIcon icon={faArrowLeftFromLine}/></CloseIcon>
      {
        loading ? (
          <>
            {[...new Array(loadingSpinners ?? 1)].map((_,i) => (
              <SideBarItem key={i}>
                <IconLoading className="loading-content"/>
                <SpanLoading className="loading-content" width={4}/>
              </SideBarItem>
            ))}
          </>
        ) : children
      }
    </SideBarContainer>
  </>
}

export { SideBarItem, Icon };
export default SideBar;
