import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SecondaryButton } from '@posten-hedwig/button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ButtonContent } from './style'


interface IIconPrimaryButtonProps extends PropsWithChildren<any> {
  icon?: IconProp
  size?: 'small' | 'medium' | 'large' | 'full' | 'mobile-full'
  type?: 'button' | 'submit'
  ariaControls?: string
  iconLast?: boolean
  disabled?: boolean
  href?: string
  onClick?: () => void
  children: any
}

const IconPrimaryButton = ({
                             icon,
                             size,
                             type,
                             ariaControls,
                             disabled,
                             href,
                             onClick,
                             iconLast,
                             children,
                           }: IIconPrimaryButtonProps) => {

  return (
    <SecondaryButton
      size={size}
      type={type}
      ariaControls={ariaControls}
      disabled={disabled}
      href={href}
      onClick={onClick}>
      <ButtonContent className={`hw-button--secondary${iconLast ? " icon-last" : ""}`}>
        {icon && <FontAwesomeIcon icon={icon}/>}
        {children}
      </ButtonContent>
    </SecondaryButton>
  );
}
export default IconPrimaryButton;