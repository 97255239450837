import { useEffect, useState } from "react";
import { impersonatingAsHeader, impersonatingAsKey, pureAuthenticatedFetch } from "@/utils/api";
import Dropdown from "../form-components/dropdown";
import { t } from "i18next";

interface Option {
  label: string;
  value: string;
}

export default function () {
  const [options, setOptions] = useState<Option[]>([]);
  const [show, setShow] = useState(false);
  const [impersonatingAs, setImpersonatingAs] = useState<string>(sessionStorage.getItem(impersonatingAsKey) ?? undefined);
  const [settingImpersonationRole, setSettingImpersonationRole] = useState<boolean>(false);

  useEffect(() => {
    pureAuthenticatedFetch("/api/metadata/roles")
      .then(async response => {
        if (response.ok) {
          const roles = await response.json() as string[];
          setOptions([{label: 'Default', value: 'default'}].concat(roles.map(role => ({label: role, value: role}))));
        } else {
          setOptions([{label: 'Default', value: 'default'}]);
        }
      });
  }, [])

  useEffect(() => {
    const empty = (options?.length <= 0 ?? true) || (options.length === 1 && options[0].value === "default");
    setShow(!empty)
  }, [options])

  useEffect(() => {
    if (!impersonatingAs) return
    const sessionStorageValue = sessionStorage.getItem(impersonatingAsKey);
    if (sessionStorageValue === impersonatingAs) return
    setSettingImpersonationRole(true)
    if (impersonatingAs === "Default") {
      sessionStorage.removeItem(impersonatingAsKey)
      setSettingImpersonationRole(false)
      if (sessionStorageValue !== impersonatingAs) location.reload()
      return
    }
    pureAuthenticatedFetch("/api/metadata/ImpersonateAsRole?role=" + impersonatingAs).then((res) => {
      if (!res.ok || res.headers.get(impersonatingAsHeader) !== impersonatingAs) {
        setImpersonatingAs("Default")
        setSettingImpersonationRole(false)
        return
      }

      if (sessionStorageValue !== impersonatingAs) {
        sessionStorage.setItem(impersonatingAsKey, impersonatingAs)
        location.reload()
      }

      setSettingImpersonationRole(false)
    })
  }, [impersonatingAs]);

  return (
    show && <>
        <h5>{t('header.changerole')}</h5>
        <Dropdown
            id="impersonator"
            name="impersonator"
            disabled={settingImpersonationRole}
            label={t('header.simulateRole')}
            options={options}
            selected={impersonatingAs}
            onChange={(e) => setImpersonatingAs(e.target.value)}
        />
    </>
  )
}
