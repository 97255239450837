import styled from "styled-components";
import { colors } from "@/ui/colors"
import { spacing } from "@/ui/spacing"
import {fontFamily} from "@/ui/typography"

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    & svg {
        margin-right: ${spacing.small2};
        font-size: var(--hw-font-size-small);
        path {
            color: ${colors.white};
        }
    }
    button:disabled > & {

    }
    .hw-button--secondary:hover > &, .hw-button--secondary:active > & {
        background-color: ${colors.black};
    }
    font-family: var(--hw-font-primary-medium);
    font-size: var(--hw-font-size-small);
    line-height: var(--hw-font-size-p);
    transition: all var(--hw-transition-time-quick) var(--hw-transition-easing-normal);
    text-align: center;
    cursor: pointer;
    word-break: normal;
    gap: ${spacing.small2};
    &.icon-last {
        flex-direction: row-reverse;
    } 
`;

export const TextContainer = styled.span`
    font-size: inherit;
    font-family: inheret;
    color: inheret;
`;