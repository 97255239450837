import { useEffect, useRef } from 'react';
import { Suggestion } from "./style"
import { Link, useNavigate } from "react-router-dom"

export interface ISearchSuggestion {
  title?: string
  subTitle?: string
  href?: string
  focus?: boolean
  onClick?: (e: any) => void
  scrollSuggestionIntoView?: boolean
}

const SearchSuggestion = ({title, focus, subTitle, href, onClick, scrollSuggestionIntoView}: ISearchSuggestion) => {
  const linkRef = useRef<HTMLAnchorElement>()
  const navigate = useNavigate()
  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        linkRef.current.focus({
          preventScroll: !scrollSuggestionIntoView
        })
      })
    }
  }, [focus])

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)
    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  const handleEnter = (e: KeyboardEvent) => {
    if (e.code === "Enter" && focus) {
      if (!href) return
      navigate(href)
    }
  }

  return (
    <Suggestion className='hw-search__suggestion' onClick={onClick}>
      <Link ref={linkRef} to={href} onTouchEnd={() => navigate(href)}>
        <div className='hw-search__suggestion-link'>
          <p className='hw-search__suggestion-title'>{title}</p>
          {subTitle && <p className='hw-search__suggestion-extra'>{subTitle}</p>}
        </div>
      </Link>
    </Suggestion>
  )
}

export default SearchSuggestion
