import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryButton } from '@posten-hedwig/button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ButtonContent } from './style'


interface IIconPrimaryButtonProps extends PropsWithChildren<any> {
  icon?: IconProp
  size?: 'small' | 'medium' | 'large' | 'full' | 'mobile-full'
  type?: 'button' | 'submit'
  spin?: boolean
  ariaControls?: string
  disabled?: boolean
  href?: string
  onClick?: () => void
}

const IconPrimaryButton = ({
                             icon,
                             size,
                             type,
                             ariaControls,
                             disabled,
                             spin,
                             href,
                             onClick,
                             children,
                           }: IIconPrimaryButtonProps) => {

  return (
    <PrimaryButton
      size={size}
      type={type}
      ariaControls={ariaControls}
      disabled={disabled}
      href={href}
      onClick={onClick}>
      <ButtonContent className="hw-button--primary">
        {icon ? <FontAwesomeIcon spin={spin} icon={icon}/> : <></>}
        {children}
      </ButtonContent>
    </PrimaryButton>
  );
}
export default IconPrimaryButton;