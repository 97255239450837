import { t } from 'i18next';
import { NewServiceAndEquipmentTileContainer, Name, Note, TextContainer } from './style';
import { WarningBadge } from '@posten-hedwig/badge'
import { fontClasses } from '@/ui/typography';

interface INewServiceAndEquipmentTile {
    name: string
    note?: string
};
const NewServiceAndEquipmentTile = ({ name, note }: INewServiceAndEquipmentTile) => {

    return (
        <NewServiceAndEquipmentTileContainer>
            <WarningBadge>    {`${t('common.new')}!`}</WarningBadge>
            <TextContainer>
                <Name className={fontClasses.technical}>
                    {name}
                </Name>
                <Note className={fontClasses.technical}>
                    {note}
                </Note>
            </TextContainer>


        </NewServiceAndEquipmentTileContainer>)
}
export default NewServiceAndEquipmentTile;