import { useEffect, useRef, useState } from "react";
import { Info } from '@/ui/icons';
import { useTranslation } from 'react-i18next';
import { IEditableMessage, IMessage } from "@/models/UnitPage/message";
import Accordian from "@/components/base/accordian";
import MessageList from "@/components/unitPage/messages/message-list";
import MessageForm, { IMessageFormRef } from "@/components/unitPage/messages/message-form";
import { PrimaryButton, PrimaryOutlineButton } from '@posten-hedwig/button'
import { ButtonContainer, MessageContainer, MessageSectionWrapper } from './styles'
import ConfirmationModal from "@/components/modal/confirmation-modal";
import IconPrimaryButton from "@/components/base/icon-primary-button";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";

interface IMessagesProps {
  editableAccess: boolean
  messages?: IMessage[]
  onSave: (message: IMessage) => Promise<boolean>
  onDelete: (idMessage: number) => Promise<boolean>
}

const MessageCard = ({ messages, editableAccess, onSave, onDelete }: IMessagesProps) => {
  const { t } = useTranslation(['translation']);
  const [activeMessages, setActiveMessage] = useState<IMessage[]>([])
  const [editableMessage, setEditableMessage] = useState<IEditableMessage>()
  const [editMode, setEditMode] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const messageToDelete = useRef<number | undefined>()
  const [inSave, setInSave] = useState(false)
  const messageFormRef = useRef<IMessageFormRef>()

  useEffect(() => {
    if (messages) {
      setActiveMessage(messages.filter(m => !m.deleted && new Date(m.fromDate).getTime() < Date.now() && Date.now() < new Date(m.toDate).getTime()))
    }
  }, [messages])

  const handleOnEdit = (idMessage: number) => {
    const messageToUpdate = activeMessages.find((msg) => msg.idMessage == idMessage)
    if (messageToUpdate) {
      setEditableMessage({
        ...messageToUpdate,
        permanently: !(messageToUpdate.fromDate && messageToUpdate.toDate)
      })
      setEditMode(true)
    }
  }

  const handleSave = () => {
    if (messageFormRef.current) {
      if (messageFormRef.current.validate()) {
        const messageToUpdate = messageFormRef.current.getEditableMessage()
        setInSave(true)
        onSave(messageToUpdate).then(ok => {
          if (ok) {
            messageFormRef.current.cancel()
            setEditMode(false)
          }
          setInSave(false)
        })
      }
    }
  }

  const handleCancel = () => {
    if (messageFormRef.current) {
      messageFormRef.current.cancel()
    }
    setEditableMessage(undefined)
    setEditMode(false)
  }

  const handleConfirmationClose = () => {
    messageToDelete.current = undefined
    setShowConfirmation(false)
  }

  const handleDelete = (idMessage: number) => {
    messageToDelete.current = idMessage
    setShowConfirmation(true)
  }

  const handleConfirm = async () => {
    if (messageToDelete.current) {
      const ok = await onDelete(messageToDelete.current);
      if (ok)
        messageToDelete.current = undefined
      setShowConfirmation(false)
      return ok;
    }
  }

  return (
    <MessageSectionWrapper>
      <Accordian title={t('unitPage.messageSection.messages') as string} defaultOpen={false} icon={Info}>
        <MessageContainer>
          {
            editableAccess && editMode && editableMessage ? (
              <>
                <MessageForm
                  ref={messageFormRef}
                  message={editableMessage}
                  mapMessageExist={editableMessage && activeMessages.findIndex(msg => msg.idMessage !== editableMessage?.idMessage && msg.mapMessage) > -1}
                  variant={'white'} />
                <ButtonContainer className="button-group">
                  {
                    inSave ? (
                      <IconPrimaryButton icon={faSpinnerThird} spin={true}>
                      </IconPrimaryButton>
                    ) : (
                      <PrimaryButton onClick={handleSave}>
                        {t('common.save')}
                      </PrimaryButton>
                    )
                  }
                  <PrimaryOutlineButton onClick={handleCancel}>
                    {t('common.cancel')}
                  </PrimaryOutlineButton>
                </ButtonContainer>
              </>
            ) : (
              <MessageList onEdit={handleOnEdit} onDelete={handleDelete} messages={activeMessages} editable={editableAccess} />
            )
          }
        </MessageContainer>
        <ConfirmationModal
          title={t('unitPage.messageSection.deleteMessage')}
          show={showConfirmation}
          onClose={handleConfirmationClose}
          onConfirm={handleConfirm}
          confirmButtonText={t('common.delete')}>
          {t('unitPage.messageSection.confirmationDeleteMessage')}
        </ConfirmationModal>
      </Accordian>
    </MessageSectionWrapper>
  );
}
export default MessageCard;