import SideBar, { Icon, SideBarItem } from "@/components/sidebar";
import HeroBanner from "@/components/hero-banner";
import { BannerBackground, ContentWrapper, InnerPageWrapper, OuterWrapper, Wrapper } from './style'
import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToTop } from "@/helpers/scroll";
import { isCaseInsensitiveMatch } from "@/helpers/validators";
import { t } from "i18next";
import Accordian from "@/components/base/accordian";
import { faArrowRight, faHome } from "@fortawesome/pro-solid-svg-icons";
import { useCheckMobileScreen, useCheckTabletScreen } from "@/helpers/screenSizeDetector";
import { Accordion } from "@postenbring/hedwig-react";

const AdminPageLayout = () => {
    const location = useLocation();
    const [lastPathSegment, setLastPathSegment] = useState(location.pathname.split("/").at(-1))
    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabletScreen();
    const [openItem, setOpenItem] = useState<
        "deals" | "openingHoursAndDeadlines" | "units" | "pbeSystem" | "areas" | "postalCodes" | "servicesAndTools" | undefined
    >(undefined);

    function toggle(item: typeof openItem) {
        setOpenItem(item === openItem ? undefined : item);
    }
    useEffect(() => {
        setLastPathSegment(location.pathname.split("/").at(-1))
        scrollToTop()
    }, [location])

    return (
        <OuterWrapper>
            <Wrapper>
                {(isMobile || isTablet) && <BannerBackground>
                    <HeroBanner loading={false} title={t('header.pbeAdministrationPanel')} />
                </BannerBackground>}
                <InnerPageWrapper>
                    <SideBar loading={false} loadingSpinners={5}>
                        <Link to="/Admin">
                            <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Admin') ? 'active' : ''}>
                                <Icon><FontAwesomeIcon icon={faHome} /></Icon>
                                <span>{t('admin.title')}</span>
                            </SideBarItem>
                        </Link>

                        <Accordion>
                            <Accordion.Item onOpenChange={() => toggle("deals")} open={openItem === "deals"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.deals.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="Deals/DocumentType">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'DocumentType') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.deals.documentType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Deals/ConsumerPriceIndex">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'ConsumerPriceIndex') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.deals.consumerPriceIndex')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Deals/OptionTypes">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OptionTypes') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.deals.optionTypes')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("openingHoursAndDeadlines")} open={openItem === "openingHoursAndDeadlines"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.openingHoursAndDeadlines.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="OpeningHoursAndDeadlines/OpeningStatusType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OpeningStatusType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.openingStatusType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="OpeningHoursAndDeadlines/OpeningHoursType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OpeningHoursType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.openingHoursType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="OpeningHoursAndDeadlines/DeadlinePeriodType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'DeadlinePeriodType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.deadlinePeriodType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="OpeningHoursAndDeadlines/DeadlineType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'DeadlineType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.deadlineType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="OpeningHoursAndDeadlines/SpecialOpeningHourTemplate">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'SpecialOpeningHourTemplate') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.specialOpeningHourTemplate')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="OpeningHoursAndDeadlines/MessageTemplate">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'MessageTemplate') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.openingHoursAndDeadlines.messageTemplate')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("units")} open={openItem === "units"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.units.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="Units/UnitTypes">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'UnitTypes') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.unitTypes')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/OrganizationTypes">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OrganizationTypes') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.organizationTypes')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/AdditionalFunctionality">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'AdditionalFunctionality') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.additionalFunctionality')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/History">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'History') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.history')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/UnitGroups">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'UnitGroups') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.unitGroups')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/OptionalMailOfficeShown">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'OptionalMailOfficeShown') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.optionalMailOfficeShown')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/DistributionType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'DistributionType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.distributionType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/EstablishmentStatusType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'EstablishmentStatusType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.establishmentStatusType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/ContactPointType">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'ContactPointType') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.contactPointType')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Units/Score">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Score') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.units.score')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("pbeSystem")} open={openItem === "pbeSystem"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.pbeSystem.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="PbeSystem/ArticlesAndFooter">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'ArticlesAndFooter') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.pbeSystem.articlesAndFooter')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="PbeSystem/Configuration">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Configuration') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.pbeSystem.configuration')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="PbeSystem/Users">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Users') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.pbeSystem.users')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("areas")} open={openItem === "areas"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.areas.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="Areas/County">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'County') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.areas.county')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Areas/Land">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Land') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.areas.land')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="Areas/Municipality">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Municipality') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.areas.municipality')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("postalCodes")} open={openItem === "postalCodes"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.postalCodes.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="PostalCodes/Denmark">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'Denmark') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.postalCodes.denmark')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="PostalCodes/Finland">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Finland') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.postalCodes.finland')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="PostalCodes/Norway">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Norway') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.postalCodes.norway')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="PostalCodes/Sweden">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Sweden') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.postalCodes.sweden')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>

                            <Accordion.Item onOpenChange={() => toggle("servicesAndTools")} open={openItem === "servicesAndTools"}>
                                <Accordion.Header>
                                    {t('admin.sideBarMenu.servicesAndTools.title')}
                                </Accordion.Header>
                                <Accordion.Content>
                                    <Link to="ServicesAndTools/Services">
                                        <SideBarItem className={`${isCaseInsensitiveMatch(lastPathSegment, 'Services') ? 'active' : ''}`}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.servicesAndTools.services')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="ServicesAndTools/ServiceCategories">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'ServiceCategories') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.servicesAndTools.serviceCategories')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="ServicesAndTools/Tools">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'Tools') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.servicesAndTools.tools')}</span>
                                        </SideBarItem>
                                    </Link>
                                    <Link to="ServicesAndTools/ToolCategories">
                                        <SideBarItem className={isCaseInsensitiveMatch(lastPathSegment, 'ToolCategories') ? 'active' : ''}>
                                            <Icon><FontAwesomeIcon className='fa-x' icon={faArrowRight} /></Icon>
                                            <span>{t('admin.sideBarMenu.servicesAndTools.toolCategories')}</span>
                                        </SideBarItem>
                                    </Link>
                                </Accordion.Content>
                            </Accordion.Item>
                        </Accordion>
                    </SideBar>


                    <ContentWrapper>
                        <Outlet />
                    </ContentWrapper>
                </InnerPageWrapper>
            </Wrapper>
        </OuterWrapper>
    );
};

export default AdminPageLayout;
