export const emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]{2,})+$";
export const phoneNumberPattern = "^[0-9]{8,10}$";
export const phoneNumberWithCountryCodePattern = "^[+]\d{1,3}[\s][0-9]{8,10}$";
export const validStartOfPostalCode = "^[0-9]{1,3}$"
export const validStartOfSwedishPostalCode = "^[0-9]{1,4}$"
export const validPostalCode = "^[0-9]{4}$"
export const validOrganizationNumber = "^[0-9]{9}$"
export const validSwedishPostalCode = "^[0-9]{5}$"
export const validSettlementAccountNumber = "^[0-9]{11}$"
export const validCustomerNumber = "^[0-9]{11}$"
export const validBaxNumber = "^[0-9]{6}$"
export const validTidNumber = "^[0-9]{8}$"
export const validUnitNumber = "^[0-9]{6}$"

export const emailValidator = (value: string): boolean => {
  const emailRegex = new RegExp(emailPattern);
  return emailRegex.test(value)
}
export const postalCodeValidator = (value: string): boolean => {
  const postalCodeRegex = new RegExp(validPostalCode);
  return postalCodeRegex.test(value)
}
export const settlementAccountNumberValidator = (value: string): boolean => {
  const settlementAccountNumberRegex = new RegExp(validSettlementAccountNumber);
  return settlementAccountNumberRegex.test(value)
}
export const customerNumberValidator = (value: string): boolean => {
  const customerNumberRegex = new RegExp(validCustomerNumber);
  return customerNumberRegex.test(value)
}
export const baxNumberValidator = (value: string): boolean => {
  const baxNumberRegex = new RegExp(validBaxNumber);
  return baxNumberRegex.test(value)
}
export const tidNumberValidator = (value: string): boolean => {
  const tidNumberRegex = new RegExp(validTidNumber);
  return tidNumberRegex.test(value)
}
export const organizationNumberValidator = (value: string): boolean => {
  const organizationNumberRegex = new RegExp(validOrganizationNumber);
  return organizationNumberRegex.test(value)
}

export const phoneNumberValidator = (value: string): boolean => {
  value = value.trim();
  let phoneNumberRegex = new RegExp(value.startsWith("+") ? phoneNumberWithCountryCodePattern : phoneNumberPattern);
  return phoneNumberRegex.test(value);
}

export const phoneNumberHasValue = (value?: string): boolean => {
  if (!value) return false
  value = value.trim()
  return value !== ''
}

export const emailHasValue = (value?: string): boolean => {
  return value && value?.trim() !== '';
}

export const emailOrPhoneNumberHasValue = (phone?: string, email?: string): boolean => {
  const hasValue = phoneNumberHasValue(phone)
  return hasValue || (email && email?.trim() !== '');
}

export const getValidationErrorField = (validations: { [prop: string]: any }) => {
  const validationErrorFields: string[] = [];
  for (const prop in validations) {
    if (typeof validations[prop] === 'string' && validations[prop] !== '') {
      validationErrorFields.push(validations[prop]);
    }
  }
  return validationErrorFields;
}

export function isCaseInsensitiveMatch(v, v2) {
  if (!v || !v2) return false;
  return v.toLowerCase() == v2.toLowerCase();
}
